import { FC, memo } from 'react';
import { appColors } from '@/constants/colors';
import { css } from '@emotion/react';
import layout from '@/constants/layout';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  isNews?: boolean;
  hasParagraph?: boolean;
  bordered?: boolean;
  imageUrl?: string;
  imageAlt?: string;
  publishedDate?: string;
  mainTitle?: string;
  newsPublishedDate?: string;
  newsHeadline?: string;
  newsContent?: string;
}

const CardImage: FC<CardProps> = ({
  isNews,
  hasParagraph,
  bordered,
  imageUrl,
  imageAlt,
  publishedDate,
  mainTitle,
  newsPublishedDate,
  newsHeadline,
  newsContent,
  ...props
}) => {
  return (
    <div
      {...{
        ...props,
        css: cardStyles,
      }}
    >
      {isNews ? (
        <div {...{ css: cardContentContainer }}>
          <p {...{ css: newsCardTitle }}>{newsHeadline}</p>
          <h4 {...{ css: cardDate }}>{newsPublishedDate}</h4>
        </div>
      ) : null}
      <img
        src={imageUrl}
        alt={imageAlt}
        {...{ css: bordered ? cardImage : cardImageWithoutBorder }}
      />
      <div {...{ css: cardContentContainer }}>
        <p {...{ css: cardTitle }}>{mainTitle}</p>
        <h4 {...{ css: cardDate }}>{publishedDate}</h4>
      </div>
      {hasParagraph ? (
        <div {...{ css: cardContentContainer }}>
          <p {...{ css: cardTitle, style: { marginTop: '-10px' } }}>
            {newsContent}
          </p>
          <div {...{ css: cardLinkContainerStyles }}>
            {' '}
            <p {...{ css: cardLinkStyles }}>
              Read More
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(CardImage);

const cardStyles = css`
  //box-shadow: 0px 10px 30px rgba(49, 94, 255, 0.08);
  transition: 0.3s;
  width: 400px;
  flex-shrink: 0;
  cursor: pointer;
  & :hover {
    filter: none;
    -webkit-filter: grayscale(0%);
  }
  @media (max-width: ${mobile}px) {
    width: 350px;
  }
`;

const cardContentContainer = css``;

const cardLinkContainerStyles = css`
  text-align: center;
  padding: 0em 0 2em 0;
`;

const cardLinkStyles = css`
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${appColors.content.tertiary};
  text-decoration: underline;
`;

const cardImage = css`
  width: 100%;
  border-radius: 16px;
`;

const cardImageWithoutBorder = css`
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
`;

const cardTitle = css`
  color: #000;
  font-family: 'Hepta Slab';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const cardDate = css`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 20px auto;
`;

const newsCardTitle = css`
  color: ${appColors.content.tertiary};
  font-family: 'Hepta Slab';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px auto;
`;
