import { useMemo } from 'react';
import { css } from '@emotion/react';
import { router, Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import Heading from '@/components/Typography/Heading';
import PressRelease from '../../assets/images/press-release.jpg';
import City from '../../assets/images/city.png';
import CardImage from '@/components/Card/CardImage';
import { Divider } from '@mui/material';
import styled from '@emotion/styled';
import Top from '../../assets/images/tenens-agency-top.jpeg';
import Thousand from '../../assets/images/1000-card.jpg';
import Dentist from '../../assets/images/dentist.jpg';
import SaveSearch from '../../assets/images/Save-Search-Card.jpg';
import LocumLog from '../../assets/images/1000-Locums-Log-art-snapshot-page.jpg';
import Burnout from '../../assets/images/Burnout-Card.jpg';
import TopSpecialties from '../../assets/images/top-specialties.png';
import TopStates from '../../assets/images/top-states.png';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  gap: 30px;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 0 3em 3em 3em;
  }
`;

const contentStyle = css`
  width: 100%;
  //background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  //padding: 50px auto;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 0 3em 3em 3em;
  }
`;

const DividerStyle = styled(Divider)`
  width: 80%;
  //border-color: ${appColors.content.tertiary};
  margin: auto;
  border-width: 1px 0px thin;
`;

const mobileFontStyle = css`
  font-size: 55px;
  color: #fff;
  @media (max-width: ${mobile}px) {
    font-size: 35px;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>News</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>
      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>

      <div {...{ css: pageContentStyle }}>
        <div>
          <Heading
            {...{
              //style: { color: '#315EFF', margin: '0' },
              variant: 'h1',
              children: 'Articles & News',
              css: mobileFontStyle,
            }}
          />
        </div>
        <div {...{ css: contentStyle }}>
          <div style={{ marginBlockEnd: 'auto' }}>
            <CardImage
              {...{
                imageUrl: TopStates,
                imageAlt: 'Locums.com infographic',
                isNews: true,
                newsHeadline:
                  'Top States with the Most New Job Opportunities on Locums.com',
                newsPublishedDate: 'Dec 23, 2024',
                hasParagraph: true,
                newsContent:
                  'Looking for your next locum tenens assignment? The hottest states for new jobs right now are: Pennsylvania, New York... ',
                onClick: () =>
                  router.get(
                    '/news/top-states-with-the-most-new-job-opportunities'
                  ),
              }}
            />
            <DividerStyle />
            <CardImage
              {...{
                imageUrl: TopSpecialties,
                imageAlt: 'Locums.com infographic',
                isNews: true,
                newsHeadline:
                  'Top Specialties and States Providers Are Searching for on Locums.com',
                newsPublishedDate: 'Dec 17, 2024',
                hasParagraph: true,
                newsContent:
                  'As the healthcare industry increasingly relies on locum tenens providers to meet growing patient demands.',
                onClick: () =>
                  router.get(
                    '/news/top-specialties-and-states-providers-searching'
                  ),
              }}
            />
            <DividerStyle />
            <CardImage
              {...{
                imageUrl: LocumLog,
                imageAlt: 'Doctor in a desk',
                isNews: true,
                newsHeadline:
                  'Do you keep a locum log yet? Here’s your reminder to start one now. ',
                newsPublishedDate: 'Dec 02, 2024',
                hasParagraph: true,
                newsContent:
                  'Working as a locum has tremendous benefits, including travel, great paychecks, the flexibility to create your own schedule, and so much more.',
                onClick: () => router.get('/news/introducing-locums-log'),
              }}
            />
            <DividerStyle />
          </div>
          <div style={{ marginBlockEnd: 'auto' }}>
            <CardImage
              {...{
                imageUrl: Burnout,
                imageAlt: 'Doctor on the floor',
                isNews: true,
                newsHeadline: 'Locums as a way to avoid burnout ',
                newsPublishedDate: 'Nov 13, 2024',
                hasParagraph: true,
                newsContent:
                  'The 2024 Medscape Physician Burnout & Depression Report: “We Have Much Work to Do”* reported that nearly half (49%) of physicians said they felt burned out at least once in their careers.',
                onClick: () => router.get('/news/avoid-burnout'),
              }}
            />
            <DividerStyle />
            <CardImage
              {...{
                imageUrl: SaveSearch,
                imageAlt: '',
                isNews: true,
                newsHeadline:
                  'Introducing Save Search: Find Locum Tenens Jobs Faster on Locums.com',
                newsPublishedDate: 'Oct 6, 2024',
                hasParagraph: true,
                newsContent:
                  'With “Save Search,” searching for jobs is easier than ever. Use Save Search to save your criteria, and get job alerts.',
                onClick: () => router.get('/news/introducing-save-search'),
              }}
            />
            <DividerStyle />
            <CardImage
              {...{
                imageUrl: Dentist,
                imageAlt: 'Dentist with a patient',
                isNews: true,
                newsHeadline:
                  'Locum tenens ease dentist shortages to advance patient care',
                newsPublishedDate: 'Aug 15, 2024',
                hasParagraph: true,
                newsContent:
                  '“While the number of dentists is on the rise, it’s still not enough to meet demand.” Read what Nick Lemay, Director of Dental Services and Recruitment at ICON Medical Network says.',
                onClick: () => router.get('/news/dentist-shortages'),
              }}
            />
            <DividerStyle />
            <CardImage
              {...{
                imageUrl: Thousand,
                imageAlt: 'Collage of  physicians',
                isNews: true,
                newsHeadline:
                  'We hit 1,000 followers in record time, thanks to you',
                newsPublishedDate: 'Jul 09, 2024',
                hasParagraph: true,
                newsContent:
                  'Locums.com is officially on a roll. In less than three months, Locums.com had 1,000 recruiters and practitioners visit, sign up, and follow the online community that’s dedicated to connecting recruiters and providers.',
                onClick: () =>
                  router.get(
                    '/news/the-state-of-locums-a-recruiter-prospective'
                  ),
              }}
            />
            <DividerStyle />
          </div>
          <div style={{ marginBlockEnd: 'auto' }}>
            <CardImage
              {...{
                imageUrl: City,
                imageAlt: 'Downtown City',
                isNews: true,
                newsHeadline:
                  'What every locum tenens provider needs to know about taxes',
                newsPublishedDate: 'May 18, 2024',
                hasParagraph: true,
                newsContent:
                  'If you’re a locum tenens provider who works temporary, short-term or long-term medical assignments at clinics or hospitals, you’ll need to learn the ins and outs of keeping up with your income, expenses, retirement contributions, and other financial responsibilities.',
                onClick: () => router.get('/news/locum-tenens-taxes'),
              }}
            />
            <DividerStyle />
            <CardImage
              {...{
                imageUrl: Top,
                imageAlt: 'A doctors sitting at a conference table',
                isNews: true,
                newsHeadline: 'Tips For Choosing A Locum Tenens Agency',
                newsPublishedDate: 'March 26, 2024',
                hasParagraph: true,
                newsContent:
                  'Are you a physician, nurse, advanced practitioner, or other healthcare specialist who is interested in working locum tenens? If so, you’ll be joining the 52,000+ (and counting!) physicians and practitioners who earn their living as locum tenens providers. And for good reason. Locum tenens offers flexibility, travel, experience, and so much more.',
                onClick: () =>
                  router.get('/news/tips-for-choosing-a-locum-tenens-agency'),
              }}
            />
            <DividerStyle />
            <CardImage
              {...{
                imageUrl: PressRelease,
                imageAlt:
                  'A pediatrician examining a child in a friendly and caring manner ',
                isNews: true,
                newsHeadline:
                  'Locums.com launches as job connector and so much more',
                newsPublishedDate: 'Jan 24, 2024',
                hasParagraph: true,
                newsContent:
                  'New York, NY – Announcing the launch of Locums.com, an online connector for locum tenens (temporary) physicians and other advanced healthcare providers and the recruiters that source job opportunities for them.',
                onClick: () => router.get('/news/press-release'),
              }}
            />

            <DividerStyle />
          </div>
        </div>
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
